<template>


      
    <v-card flat outlined  :dark="$store.getters.getColorPalette().isDark" class="px-1 py-0 mx-1 my-1  text-capitalize  lighten-1" :style="{'border': '1px solid '+ $store.getters.getColorPalette().cardBorderColor, 'background-color':color}" >
      <v-container class="px-0 py-0" fluid >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
      
        
        
            <v-card-title class="px-1 py-0 mx-0 my-0 ">
              <v-row>
                <v-col align="right">
              <span class="text-subtitle-2">{{name}}</span> <span>
              <EditParameterModal :parameter="parameter" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" />
      <DeleteConfirmation  v-on:confirm="deleteParameter()"  title="Delete Confirmation"  description="Are you sure you want to delete this?">          
    <v-icon
    v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])"
        small
       
      >
        mdi-delete
      </v-icon>
    </DeleteConfirmation>
      </span>
      </v-col>
              </v-row>
      </v-card-title>
        <v-divider></v-divider>
        <v-hover v-slot="{ hover }">
        <v-card-subtitle class="px-1 py-0 mx-0 my-0 " @click="overlay=!overlay" >
        <v-row>
            <v-col>
                
                <strong class="text-h6" >{{latestVal}}</strong> {{parameter.unit}}
               
                
            </v-col>
            
        </v-row>
        <v-row v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
          <v-col>
            {{$store.getters.getTextMap().address}}:{{address}}
          </v-col>
          <v-col>
            {{$store.getters.getTextMap().offset}}:{{offset}}
          </v-col>
        </v-row>
        <a hidden ref="csvDownload"></a>
         <v-overlay
          absolute
          :value="hover"
        >
        <v-row justify="center" align="center" align-content="center">
            <v-col justify="center" align="center" align-content="center">
          <v-btn
          :color="$store.getters.getColorPalette().submitbtnColor"
            small
            @click="dialog = true"
          >
          {{$store.getters.getTextMap().view_details}}
            <v-icon>mdi-chart-line</v-icon>
          </v-btn>
            </v-col>
        </v-row>
        
        
        </v-overlay>


<v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
    <v-card  :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
    <v-toolbar
         :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().foregroundColorCode"
        >
          <v-btn
            icon
        :dark="$store.getters.getColorPalette().isDark"
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{parameter.name ?parameter.name : parameter.parameter}} Details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
            :dark="$store.getters.getColorPalette().isDark"
              text
              @click="dialog = false"
            >
            {{$store.getters.getTextMap().close}}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container  :style="{background: $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode}">
            <v-row>
              
          <v-col align="left" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess','betaaccess'])"   :color="$store.getters.getColorPalette().accentCode">
            <b >{{$store.getters.getTextMap().parameter_id}}:</b> {{parameter.parameter}}
          </v-col>
          <v-col align="right" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
            <AttachTriggerToParamModal :parameter="parameter" />
          </v-col>
        </v-row>
        <v-row v-if="attachedTriggers && attachedTriggers.length>0">
          <v-col v-if="loading"><v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular></v-col>
          <v-col v-if="!loading">
            <span><strong    :color="$store.getters.getColorPalette().accentCode">{{$store.getters.getTextMap().attached_triggers}}:</strong></span>
            
        <v-chip
      class="ma-2"
      close
      @click:close="removeTrigger(trigger)" 
      color="indigo"
      text-color="white" v-for="trigger in attachedTriggers"
        :key="trigger.trigger_id">{{$store.getters.getTriggerById(trigger.trigger_id).name}}
        </v-chip></v-col>
        </v-row>

        <v-divider></v-divider>
        <v-row v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
          <v-col>
            <ParamMQTTMap :param_id="parameter.param_id" />
          </v-col>
        </v-row>
        <v-divider></v-divider>
<br>
<v-row no-gutters v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess','write'])">
  <v-col v-if="parameter && parameter.writable"><v-btn small @click="disableWrite">{{$store.getters.getTextMap().disable_write}}</v-btn></v-col>
  <v-col v-else><v-btn small @click="enableWrite">{{$store.getters.getTextMap().enable_write}}</v-btn></v-col>
</v-row>
<span v-if="parameter && parameter.writable">
        <v-row>
          <v-col cols="12">
            <ParamWritePanel :isCoil="coil" :param_id="parameter.parameter" />
          </v-col>
        </v-row>

      </span>
        <v-row v-if="dataType=='Word'">
          <v-col>
            <WordParamDetails :parameter="parameter" :tableData="streamData" />
          </v-col>
        </v-row>
        <v-row >
            <v-col>
       <LineGraphByTimeStamp :label="name" :graphData="streamData" dataKey="data" />
            </v-col>
        </v-row>
        <v-divider></v-divider>
        
        </v-container>
    </v-card>
        </v-dialog>
        </v-card-subtitle>
        </v-hover>
</v-container>        
    </v-card>

</template>

<script>
import LineGraphByTimeStamp from '@/components/graphs/LineGraphByTimeStamp'
import EditParameterModal from '@/components/modals/EditParameterModal'
import AttachTriggerToParamModal from '@/components/modals/AttachTriggerToParamModal'
import WordParamDetails from '@/components/panels/WordParamDetails'
import ParamMQTTMap from '@/components/crud_components/ParamMQTTMap'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import ParamWritePanel from '@/components/crud_components/control/ParamWritePanel'
import FormRules from '@/utillities/FormRules'
import InfoAlert from '@/components/InfoAlert'
// import jsPDF from 'jspdf'
import moment from 'moment'
import axios from 'axios'
import 'jspdf-autotable'
// import { applyPlugin } from 'jspdf-autotable'
// applyPlugin(jsPDF)
import {io} from 'socket.io-client'
export default {
    name:'Parameter',
    props:['parameter'],
    components:{
    LineGraphByTimeStamp,
    EditParameterModal,
    AttachTriggerToParamModal,
    WordParamDetails,
    ParamMQTTMap,
    DeleteConfirmation,
    InfoAlert,
    ParamWritePanel
},
    data(){
        return {
             showDialog:false,
            overlay:false,
            dialog:false,
            info:'Error',
            showDismissibleAlert:false,
            isActive:false,
            loading:false,
            stream:null,
            writeVal:null,
            val:'-',
            rules:FormRules.rules,
            streamData:[]

        }
    },
    created(){
    //this.socket=io('https://localhost:80');
    this.stream=io(this.$store.state.streamApi,{withCredentials: true, auth:{token:this.$store.state.jwt}})
  },
  beforeDestroy() {
    //this.socket.close()
    //console.log('before destroy')
    this.stream.close()
  },
    mounted(){
      //this.streamData=this.currentData
      this.stream.on("connect_error", (err) => {
  console.log(err.message); // prints the message associated with the error
});
      this.stream.on('error',data=>{
      console.log(data)
      
    })
    this.stream.on('parameter',data=>{
      //console.log(data)
      if(data.constructor==String){
        data=JSON.parse(data)
      }
      this.val=data.data
      this.streamData.push(data)
      //console.log(data)
      if(this.streamData.length>50){
        this.streamData.shift()
      }
    })
    this.stream.emit('getParam',this.parameter.parameter)
    },
    computed: {
      color(){
        if(this.parameter && this.parameter.disabled){
          return this.$store.getters.getColorPalette().backgroundColorCode
        }
        if(this.timeDelta > 1.5 ){
          return this.$store.getters.getColorPalette().alertColor
        }/*else if(this.coil){
          return this.latestVal=='On' ?this.$store.getters.getColorPalette().successColor:this.$store.getters.getColorPalette().backgroundColorCode
        }*/else{
         return this.$store.getters.getColorPalette().successColor
        }
      },
        currentData(){
            //console.log(this.parameter.parameter)
            return this.$store.getters.getCurrentParameterDataById(this.parameter.parameter)
        },
        latestVal(){
            if( this.streamData.length>0){
                //console.log("current data size accurate")
                /*if(this.timeDelta>1.5){
                  return '-'
                }*/
                if(this.coil){
                  return this.streamData[this.streamData.length-1]['data']==1?'On':'Off'
                }
            return Math.round(Number(this.streamData[this.streamData.length-1]['data'])*100)/100

            }
            return '-'
        },
        timeDelta(){
            if( this.streamData.length>0){
                //console.log("current data size accurate")
            return  (Number(moment().format("X")) - Number(this.streamData[this.streamData.length-1]['timestamp']))/(2*60)
            }
            return 2
        },
        name(){
          if(this.parameter){
            return this.parameter.name ? this.parameter.name : this.parameter.unit
          }
          return null
            
        },
        timeUnit(){
          if( this.currentData.length>0 && (Number(this.currentData[this.currentData.length-1]['timestamp']) - Number(this.currentData[0]['timestamp']))>(60*60*2)){
            return 'hour'
          }
          return 'minute'
        },
        unit(){
          if(this.parameter.unit){
                    return this.parameter.unit
          }else{
            return ''
          }
        },
        address(){
          return this.parameter.parameter.split(',')[5]
        },
        offset(){
          return this.parameter.parameter.split(',')[6]
        },
        attachedTriggers(){
          let triggers=this.$store.getters.getTriggerIDsByParameter(this.parameter.parameter)
          //let triggers=this.$store.state.paramTriggerMapping.filter(x=>x.parmeter==this.parameter.parameter)
          if(triggers){
            return triggers
          }else{
            return []
          }
        },
        dataType(){
          if(!isNaN(this.parameter.data_type)){
            return this.$store.getters.getDataTypeById(this.parameter.data_type)?.type
          }
          return null
        },
        coil(){
          if(this.parameter && this.parameter.command==1) return true
          return false
        }
    },
    methods: {
        downloadPDF(){
          //let pdf = new jsPDF('landscape','pt',[512,600]);
          //  let pdf = new jsPDF();
            //pdf.table(20, 30, this.currentData);
            let header=['Time',this.name]
            let body=[]
            for (let i of this.currentData){
                let temp=[]
                temp.push(moment(Number(i['timestamp'])*1000).format('MMMM Do YYYY h:mm a'))
                temp.push((Math.round(Number(i['data'])*100)/100)+this.unit)
                
                body.push(temp)
            }
//             pdf.autoTable({
//   head: [header],
//   body: body,
// })
//             pdf.save(this.name+'_'+moment().format('YYYYMMDDHHmm')+'.pdf');
            let csvHeader=header.join(',')+"\n"
            let csv=csvHeader
            csv+=body.map(e => e.join(",")).join("\n");
            let csvData = new Blob([csv], { type: 'text/csv' });  
            let csvUrl = URL.createObjectURL(csvData);
            //let hiddenElement = document.createElement('a');
            let hiddenElement = this.$refs.csvDownload;
            hiddenElement.href = csvUrl;
            hiddenElement.target = '_blank';
            hiddenElement.download = this.name+'_'+moment().format('YYYYMMDDHHmm') + '.csv';
            hiddenElement.click();
        },
        deleteParameter(){
          
               //this.$store.dispatch('deleteParameter',this.parameter)
               this.$emit('deleteParameter',this.parameter)
              

           
          
        },
        enableWrite(){
          let payload={
            param_id:this.parameter.parameter
          }
          this.loading=true
          axios.post(this.$store.state.api+'enableWrite',payload,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        //this.$store.commit('addParameter',d)
        //console.log("Parameter added "+d)
        this.$store.dispatch('refreshParameters')
        
        this.loading=false
        this.info="Write Enabled"
        this.showDismissibleAlert=true
            //this.dialog=false
        
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{console.log(err)
    this.info=err
    this.loading=false
    this.showDismissibleAlert=true
    });

        },
        disableWrite(){
          let payload={
            param_id:this.parameter.parameter
          }
          this.loading=true
          axios.post(this.$store.state.api+'disableWrite',payload,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        //this.$store.commit('addParameter',d)
        //console.log("Parameter added "+d)
        this.$store.dispatch('refreshParameters')
        
        this.loading=false
        this.info="Write Disabled"
        this.showDismissibleAlert=true
            //this.dialog=false
        
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{console.log(err)
    this.info=err
    this.loading=false
    this.showDismissibleAlert=true
    });

        },
       
        writeCoil(state){
          /*let payload={
            data:[state],
            ...this.parameter
          }*/
          this.writeVal=state
          let payload={
            value:state,
            parameter:this.parameter.parameter
          }
          this.loading=true
          axios.post(this.$store.state.api+'writeParam',payload,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        //this.$store.commit('addParameter',d)
        //console.log("Parameter added "+d)
        
        this.loading=false
        this.info="Command Sent"
        this.showDismissibleAlert=true
            //this.dialog=false
        
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{console.log(err)
    this.info=err
    this.loading=false
    this.showDismissibleAlert=true
    });
        },
        removeTrigger(trigger){
          //console.log(trigger)
          /*let trigs=Object.assign([],this.parameter.triggers)
          trigs=trigs.filter((data)=>{return data!=trigger})
          let payload={
            triiger:trigs,
            parameter:this.parameter.parameter
          }*/
          this.loading=true
          axios.post(this.$store.state.api+'removeTriggerFromParam',trigger,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        //this.$store.commit('addParameter',d)
        this.$store.dispatch('refreshParamTriggerMappings')
        //console.log("Parameter added "+d)
        
        this.loading=false
        this.info="Trigger removed"
        this.showDismissibleAlert=true
            //this.dialog=false
        
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{console.log(err)
    this.info=err
    this.loading=false
    this.showDismissibleAlert=true
    });
        },
          cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
    },
}
</script>